<template>
   <Breadcrumbs main="Bonus UI" title="" /> 
  <div class="container-fluid text-start">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>{{this.currentLanguage ==='en'?"How to order":"วิธีการสั่งซื้อ"}}</h5>
          </div>
          <div class="card-body">

            <section class="cd-container" id="cd-timeline">
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-picture bg-primary"><i class="fa fa-calendar"></i></div>
                <div class="cd-timeline-content">
                  <h4>1. {{this.currentLanguage ==='en'?"Choose the activity you are participating":"เลือกกิจกรรมที่เข้าร่วม"}}</h4>
                  <p class="m-0">{{this.currentLanguage ==='en'?"You can choose participating activities at":"สามารถเลือกกิจกรรมที่เข้าร่วมได้จาก"}} <a href="https://www.running.in.th"> http://www.running.in.th</a></p><span
                    class="cd-date">STEP <span class="counter digits"> 1</span></span>
                </div>
              </div>
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-picture bg-secondary"><i class="fa fa-search"></i></div>
                <div class="cd-timeline-content">
                  <h4>2. {{this.currentLanguage ==='en'?"Search for pictures":"ค้นหารูปภาพ"}}</h4>
                  <p class="m-0">{{this.currentLanguage ==='en'?"Upload youe face photo for searching":"อัพโหลดภาพใบหน้า เพื่อใช้ในการค้นหา"}}</p>
                  <span class="cd-date">STEP <span class="counter digits">2</span></span>
                </div>
              </div>
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-picture bg-success"><i class="icon-image"></i></div>
                <div class="cd-timeline-content">
                  <h4>3. {{this.currentLanguage ==='en'?"Choose the picture you like, add it to the cart":"เลือกรูปที่ถูกใจ ใส่ในตะกร้า หรือจะเหมาๆ"}}</h4>
                  <span
                    class="cd-date">STEP <span class="counter digits">3</span></span>
                </div>
              </div>
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-location bg-info"><i class="fa fa-pencil-square-o"></i></div>
                <div class="cd-timeline-content">
                  <h4>4. {{this.currentLanguage ==='en'?"fill phone number and email to use for receiving links To download images":"ป้อนข้อมูลหมายเลขโทรศัพท์ และอีเมลล์เพื่อใช้สำหรับรับลิ้ง ในการดาวน์โหลดรูป"}}</h4>
                  <p>{{this.currentLanguage ==='en'?"Choose payment method":"เลือกวิธีการชำระเงิน"}}</p>
                  <span class="cd-date">STEP <span class="counter digits">4</span></span>
                </div>
              </div>
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-location bg-warning"><i class="fa fa-credit-card"></i></div>
                <div class="cd-timeline-content">
                  <h4>5. {{this.currentLanguage ==='en'?"Payment":"ชำระเงิน"}}</h4>
                  <p> {{this.currentLanguage ==='en'?"Payment":"ชำระเงิน"}}</p>
                  <p>{{this.currentLanguage ==='en'?"When payment is complete, click Refresh page. The system will display a page for downloading images and will send an SMS or email with a download link again.":"เมื่อชำระเงินเรียบร้อยแล้ว กด Refresh page ระบบจะแสดงหน้าสำหรับดาวน์โหลดรูปภาพ และจะส่ง SMS หรือ อีเมลล์ลิ้งสำหรับดาวน์โหลด ให้อีกครั้ง"}}</p>
                  <span
                    class="cd-date">STEP <span class="counter digits">5</span></span>
                </div>
              </div>
              <div class="cd-timeline-block" >
                <div class="cd-timeline-img cd-movie bg-danger"><i class="fa fa-volume-control-phone"></i></div>
                <div class="cd-timeline-content">
                  <h4>{{this.currentLanguage ==='en'?"If you have any problems You can contact us immediately.":"หากพบปัญาการใช้งาน สามารถติดต่อเราได้ทันที"}}</h4>
                  <p>Line @running.in.th</p>
                  <span class="cd-date">FINAL STEP</span>
                </div>
              </div>
            </section>

          </div>
      </div>
    </div>
  </div>
</div></template>


<script>
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'

export default {
  setup() {
    useHead({
      title: computed(() => `วิธีการสั่งซื้อ ค้นหารูปความประทับใจด้วยใบหน้า | RUNNING.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `วิธีการสั่งซื้อ ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `วิธีการสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,running,runninghub,facesearch`),
        }
        ],
     
    })
  },
  data() {
    return {
      currentLanguage: 'th'   
    };
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
};
</script>