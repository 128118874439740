import { createWebHistory, createRouter } from "vue-router";
import Body from '../components/body';



// comingsoon
import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';

// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

import events from '../pages/running/events.vue'
import eventsDetail from '../pages/running/event_detail.vue'
//import eventsDetail from '../pages/running/event_detail_mansonry.vue'
import mansonry from '../pages/running/mansonry.vue'

import events_detail_wholesale from '../pages/running/event_detail_wholesale.vue'
import event_race_detail from '../pages/running/event_race_detail.vue'
import cart from '../pages/running/cart.vue'
import cartopn from '../pages/running/cartopn.vue'
import cart_wholesale from '../pages/running/cart_wholesale.vue'
import eventqr from '../pages/running/eventqr.vue'
import download from '../pages/running/download.vue'
import d from '../pages/running/download.vue'
import dz from '../pages/running/download_zip.vue'
import dcc from '../pages/running/download_creditcard.vue'
import dd from '../pages/running/download_direct.vue'
import download_page from '../pages/running/download_ios.vue'

import howToOrder from '../pages/running/how_to_order.vue'
import findOrder from '../pages/running/findOrder.vue';
import about from '../pages/running/about.vue';
import terms_of_use from '../pages/running/terms_of_use.vue';
import how_to_use from '../pages/running/how_to_use.vue';
import refund_policy from '../pages/running/refund_policy.vue'

const routes = [
  {
    path: '/',
    component: Body,

    children: [
      {
        path: '',
        name: 'events',
        component: events,
        meta: {
          title: 'ค้นหารูปความประทับใจด้วยใบหน้า | RUNNING.IN.TH',
        }
      }, {
        path: ':event_id',
        name: 'eventsDetail',
        component: eventsDetail,
        meta: {
          title: 'รูปงานวิ่ง | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: 'mansonry',
        name: 'mansonry',
        component: mansonry,
        meta: {
          title: 'รูปงานวิ่ง | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      
      
      {
        path: ':event_id/wholesale',
        name: 'events_detail_wholesale',
        component: events_detail_wholesale,
        meta: {
          title: 'ภาพวิ่งราคาเหมาๆ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },{
        path: '/event/:event_id',
        name: 'event_race_detail',
        component: event_race_detail,
        meta: {
          title: 'รูปงานวิ่ง | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      }, 
      {
        path: "cart",
        name: "cart",
        component: cart,
        meta: {
          title: "ตะกร้า | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน",
        },

      },{
        path: "cartopn",
        name: "cartopn",
        component: cartopn,
        meta: {
          title: "ตะกร้า | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน",
        },

      },{
        path: "cart-wholesale",
        name: "cart_wholesale",
        component: cart_wholesale,
        meta: {
          title: "ภาพวิ่งเหมาๆ ตะกร้า | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน",
        },

      },
      {
        path: "how-to-order",
        name: "howToOrder",
        component: howToOrder,
        meta: {
          title: "วิธีการสั่งซื้อรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน",
        },
      },
       {
        path: 'eventqr/:q',
        name: 'eventqr',
        component: eventqr,
        meta: {
          title: 'eventqr | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },{
        path: '/download/:q',
        name: 'download',
        component: download,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/d/:q',
        name: 'd',
        component: d,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/dz/:q',
        name: 'dz',
        component: dz,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/dcc/:q',
        name: 'dcc',
        component: dcc,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/dd/:q',
        name: 'dd',
        component: dd,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/dp/:q',
        name: 'dp',
        component: download_page,
        meta: {
          title: 'โหลดรูปภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/about',
        name: 'about',
        component: about,
        meta: {
          title: 'เกี่ยวกับเรา | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      }, 
      {
        path: '/find-order',
        component: findOrder,
        name: 'findOrder',
        meta: {
          title: 'ค้นหาคำสั่งซื้อ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      
      {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: terms_of_use,
        meta: {
          title: 'ข้อตกลงการใช้บริการ และ นโยบายความเป็นส่วนตัว | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/refund-policy',
        name: 'refund-policy',
        component: refund_policy,
        meta: {
          title: 'นโยบายการคืนเงิน | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      {
        path: '/how-to-use',
        name: 'how-to-use',
        component: how_to_use,
        meta: {
          title: 'วิธีการโหลดภาพ | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },
      
      {
        path: '/404',
        name: '404',
        component: Error404,
        meta: {
          title: 'Page not found | RUNNING.IN.TH ค้นง่าย หาเจอแน่นอน',
        }
      },

    ]
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router