<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-primary">
            <div class="card-body">
              <p>&nbsp;</p>
              <div v-if="this.currentLanguage === 'en'" >
                <div class="media-body">
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>Terms of Use & PDPA</h4>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I acknowledge and agree to the Terms of Use. and read and understand the system policies
To comply with the law regarding personal data protection. Personal Data Protection Act 2019 (Personal Data Protection Act: PDPA)
and consistent with Personal data protection policy of the website running.in.th </p>
                  <b>purpose for collecting personal information</b>
                  <p>To be able to fulfill the objectives of the service users. In using various services and inform information to service users.</p>
                   <b>Collection of personal information</b>
                   <p>Storage system Ordering products and/or services by users.</p>
                   <b>Data storage period</b>
                   <p>The system will maintain the information received from users very well. With a standardized security system Until it is corrected, changed, or canceled by the user.</p>
                    <p style="text-align: right;" >RUNNING.IN.TH</p>
                </div>
                
              </div>
              <div v-else >
                <div class="media-body">
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>ข้อตกลงการใช้บริการ และ นโยบายความเป็นส่วนตัว (Terms of Use & PDPA)</h4>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้ารับทราบและยินยอมในข้อตกลงการใช้งาน และอ่านทำความเข้าใจนโยบายของระบบ
เพื่อให้เป็นไปตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (Personal Data Protection Act: PDPA) 
และสอดคล้องกับ นโยบายการคุ้มครองข้อมูลส่วนบุคคล ของเว็บไซต์ running.in.th </p>
                  <b>วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</b>
                  <p>เพื่อให้สามารถทำตามวัตถุประสงค์ของผู้ใช้บริการ ในการใช้บริการต่างๆ และแจ้งข้อมูลไปยังผู้ใช้บริการ</p>
                  <b>การเก็บข้อมูลส่วนบุคคล</b>
                  <p>ระบบจัดเก็บข้อมูล การสั่งซื้อสินค้า และ/หรือ บริการ ของผู้ใช้บริการ</p>
                  <b>ระยะเวลาในการจัดเก็บข้อมูล</b>
                  <p>ระบบจะเก็บรักษาข้อมูลที่ได้รับจากผู้ใช้บริการเป็นอย่างดี ด้วยระบบรักษาความปลอดภัยที่ได้มาตรฐาน จนกว่าจะมีการแก้ไข เปลี่ยนแปลง หรือยกเลิกโดยผู้ใช้บริการ</p>
                    <p style="text-align: right;" >RUNNING.IN.TH</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentLanguage: 'th'   
    };
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
};
</script>