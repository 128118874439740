<template>
  <Breadcrumbs title="REFUND POLICY" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-primary">
            <p>&nbsp;</p>
            <div class="card-body">
              <div v-if="this.currentLanguage === 'en'" >
                <div class="media-body" >
                  <p><vue-feather type="file-text"></vue-feather></p>
                   <h4>Refund Policy (REFUND POLICY)</h4>
                   <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Provider) would like to inform you of the refund policy as an agreement and to create understanding about the use of website services as follows.</b>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RUNNING.IN.TH Will refund the product price to the service recipient. In the event that the product cannot be delivered as ordered by the service recipient.
                     which is caused by a system failure of the service provider Or in the case where the picture has already been received, the picture is unclear or blurred and a refund is requested only. </p>
                  
                     <p style="text-align: right;" >RUNNING.IN.TH</p>
                </div>
              </div>
              <div v-else>
                <div class="media-body" >
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>นโยบายการคืนเงิน (REFUND POLICY)</h4>
                  <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; RUNNING.IN.TH (ผู้ให้บริการ) ขอแจ้งนโยบายการคืนเงินเพื่อเป็นข้อตกลงและสร้างความเข้าใจเกี่ยวกับการใช้บริการเว็บไซต์ ดังนี้</b>                     
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RUNNING.IN.TH จะทำการคืนเงินค่าสินค้าให้กับผู้รับบริการ ในกรณีที่ไม่สามารถจัดส่งสินค้าตามที่ผู้รับบริการสั่งซื้อได้ 
                    อันมาจากเหตุระบบของทางผู้ให้บริการขัดข้อง หรือกรณีที่ได้รูปไปแล้ว รูปไม่ชัด หรือรูปเบลอ และมีการขอคืนเงิน เท่านั้น </p>
                  
                    <p style="text-align: right;" >RUNNING.IN.TH</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentLanguage: 'th'   
    };
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
};
</script>