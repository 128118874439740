<template>
  <Breadcrumbs title="วิธีการโหลดภาพ (How to " main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div >
                <div class="media-body">
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>วิธีการโหลดภาพ (How to use)</h4><br>
                  <b>การโหลดผ่านอุปกรณ์ iPhone, iPad</b><br>
                  <b>การโหลดผ่านอุปกรณ์ แอนดรอย</b><br>
                  <b>การโหลดผ่านอุปกรณ์ คอมพิวเตอร์</b>
                  
                  <p>หากติดปัญหาในการใช้งาน สามารถสอบถามข้อมูลเพิ่มเติมได้ที่ Facebook : <a target="_blank" href="https://www.facebook.com/running.in.th">RUNNING.IN.TH</a></p>
                    <p style="text-align: right;" >RUNNING.IN.TH</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-8 col-sm-6 box-col-6">
          <div class="card bg-primary">
            <div class="card-body">
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>ติดต่อเรา</h2>
                  <p>97/88 regent orchid เทอดไท 33 ธนบุรี กรุงเทพ 10600</p>
                  <p>email: info@running.in.th</p>
                  <p>Lne: @running.in.th</p>
                  <p>page: https://web.facebook.com/running.in.th</p>
                </div>
                <vue-feather type="navigation"></vue-feather>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>