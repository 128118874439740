<template>
  <Breadcrumbs main="Ecommerce" title="" />
  <div class="container-fluid text-start">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" v-if="Object.keys(cart_store_wholesale.cart).length <= 0">
          <div class="card-body">
            <div class="card-header">
              <h4>{{this.currentLanguage ==='en'?"Order information":"รายการสั่งซื้อ และชำระเงิน"}}</h4>
            </div>
            <div class="row">
              <div class="d-flex justify-content-center">
                <div class="card-header">
                  <vue-feather
                    type="shopping-cart"
                    style="width: 100px; text-align: center"
                  ></vue-feather>
                  <h5>{{this.currentLanguage ==='en'?"Not found":"ไม่พบรูปในตะกร้า"}}</h5>
                </div>
              </div>
            </div>
            <div class="row">
              <button class="btn btn-primary" @click="prvback()">
                {{this.currentLanguage ==='en'?"search again":"เริ่มค้นหารูปใหม่"}}
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card">
            <div class="card-body">
              <div class="card-header">
                <h4>{{this.currentLanguage ==='en'?"Order information":"รายการสั่งซื้อ และชำระเงิน"}}</h4>
                <p>
                  <b>{{this.currentLanguage ==='en'?"Event":"กิจกรรม"}} {{ this.eventDetails.title }} </b>
                </p>
                <p>
                  <span
                    v-for="(_package, index) in this.eventDetails.package_hb"
                    :key="index"
                    class="btn btn-outline-info">
                    <b>{{this.currentLanguage ==='en'?_package.name_en:_package.name}}</b>
                  </span>
                </p>
              </div>
              <div class="container-fluid">
                <figure v-for="(src, index) in cart_store_wholesale.cart" :key="index">
                  <div class="gallery gellerycont">
                    <img
                      :src="src.thumbnail_file_name"
                      :alt="src.original_file_name"
                      loading="lazy"
                    />

                    <input type="checkbox" :id="src.id" />
                    <label style="font-size: small;"
                      class="btn"
                      @click="
                        cart_store_wholesale.removeImageInCart(src.id),
                          this.checkOnceEventId()
                      "
                      :for="src.id"
                      >{{this.currentLanguage ==='en'?"Remove":"-ลบ"}}</label
                    >
                  </div>
                </figure>
              </div>
            </div>

            <div class="card-body">
              <div class="card-header">
                <div class="row">
                  <button class="btn btn-primary" @click="prvback()">
                    {{this.currentLanguage ==='en'?"Add more images":"กลับไปค้นหารูปใหม่"}}
                  </button>
                  <h3></h3>
                  <button
                    class="btn btn-danger"
                    @click="cart_store_wholesale.clear_all_cart_in_cart_page()"
                  >
                    {{this.currentLanguage ==='en'?"Remove all":"ลบรูปทั้งหมดในตะกร้า"}}
                  </button>
                  <h3></h3>
                  <button
                    v-if="
                      this.is_mhao_flag_init === 'Y' &&
                      this.cart_store_wholesale.is_mhao_flag !== 'YES'
                    "
                    class="btn btn-primary"
                    @click.prevent="
                      this.cart_store_wholesale.select_pro_mhao(
                        'Y',
                        this.eventDetails.is_hybrid,
                        this.eventDetails.mhao_price
                      )
                    "
                  >
                    +<vue-feather
                      type="shopping-cart"
                      stroke-width="2"
                    ></vue-feather>
                    {{ this.eventDetails.caption_button_buy_all }} ( {{this.currentLanguage ==='en'?"Avg":"เฉลี่ย"}}
                    {{
                      (
                        this.eventDetails.mhao_price /
                        this.cart_store_wholesale.cart_latest.length
                      ).toFixed(2)
                    }}
                    ) {{this.currentLanguage ==='en'?"per item":"ต่อใบ"}}
                  </button>
                </div>
              </div>
              <div
                class="table-responsive"
                style="margin-left: auto; margin-right: 0"
              >
                <table
                  class="table align-to-right"
                  style="width: 80%; text-align: center"
                >
                  <thead>
                    <tr class="border-bottom-light">
                      <th scope="col" style="text-align: right; width: 50%">
                        
                        <p v-if="this.currentLanguage ==='th'" >จำนวนรูป ({{ cart_store_wholesale.cart.length }} ใบ)</p>
                        <p v-if="this.currentLanguage ==='en'">Number of photos ({{ cart_store_wholesale.cart.length }} )</p>
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ this.cart_store_wholesale.cal_cart.cart_full_amt_no_pomo }} THB
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr class="border-bottom-light">
                      <th scope="col" style="text-align: right">
                        <p>
                          <span
                            v-for="(_package, index) in this.eventDetails.package_hb"
                            :key="index"
                            class="badge badge-pill badge-info"
                            ><vue-feather
                              type="paperclip"
                              size="20"
                            ></vue-feather
                            ><b>{{this.currentLanguage ==='en'?_package.name_en:_package.name}}</b>
                          </span>
                          {{this.currentLanguage ==='en'?"Discount":"ส่วนลด"}}
                        </p>
                      </th>
                      <th
                        scope="col"
                        style="text-align: right; vertical-align: bottom"
                      >
                        <p>
                          - {{ this.cart_store_wholesale.cal_cart.cart_discount }} THB
                        </p>
                      </th>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr class="border-bottom-dark">
                      <th scope="col" style="text-align: right">
                        {{this.currentLanguage ==='en'?"Total":"ยอดรวมหลังหักส่วนลด"}}
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ this.cart_store_wholesale.total }}
                        THB
                      </th>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr class="border-bottom-white">
                      <th scope="col" style="text-align: right">
                        {{this.currentLanguage ==='en'?"Payment Processing Fees(5%)":"ค่าธรรมเนียมการชำระเงิน(5%)"}}
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ this.cart_store_wholesale.cal_cart.cart_payment_fee }}
                        THB
                      </th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="border-bottom-primary">
                      <th scope="col" style="text-align: right">
                        {{this.currentLanguage ==='en'?"Total payment":"ยอดชำระสุทธิ"}}
                      </th>
                      <th scope="col" style="text-align: right">
                        <b>
                          {{ this.cart_store_wholesale.cal_cart.cart_final_payment }}
                          THB</b
                        >
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row" v-if="this.is_once_event_id === 'N'">
            <div class="d-flex justify-content-center">
              <h2 style="color: red">
                <u>{{this.currentLanguage ==='en'?"Pls choose image from only once event":"กรุณาเลือกรูปในตะกร้าที่มาจากกิจกรรมเดียวกัน เท่านั้น"}}</u>
              </h2>
            </div>
            <div>&nbsp;</div>
          </div>

          
          
          
          <form @submit.prevent="submitCart" class="needs-validation"
            v-if="this.is_once_event_id !== 'N' && this.eventDetails.is_free_download_keep_contract !== 'Y' && this.cart_store_wholesale.cal_cart.cart_final_payment !== '0.00'"
          >
            <div class="col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="media">
                        <vue-feather class="m-r-30" type="info"></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500"></h6>
                          <div class="row">
                            <div class="mb-3 col-sm-6">
                              <label for="moblieNo" class="form-label"
                                ><h3>
                                  {{this.currentLanguage ==='en'?"Mobile number":"หมายเลขโทรศัพท์"}}
                                  <span style="color: red">*</span>
                                </h3>
                                
                                <small class="form-text text-muted"
                                  >{{this.currentLanguage ==='en'?"Input only number":"ป้อนแค่ตัวเลขไม่ต้องมีขีด,ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อชำระเงินสำเร็จ"}}
                                </small>
                              </label>
                              <input
                                required
                                maxlength="10"
                                minlength="10"
                                inputmode="numeric"
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                id="moblieNo"
                                placeholder="ex.0901234567"
                                v-model="this.cart_store_wholesale.mobileNo"
                              />
                            </div>
                            <div class="mb-3 col-sm-6">
                              <label for="email"
                                ><h3>Email <span
                                    style="
                                      font-size: small;
                                      background: inherit;
                                    "
                                    >{{this.currentLanguage ==='en'?"":"(ถ้ามี)"}}</span
                                  >
                                </h3>
                                <small class="form-text text-muted"
                                  >{{this.currentLanguage ==='en'?"Optional":"หากไม่มีให้เว้นว่าง"}}
                                </small>
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                inputmode="email"
                                placeholder="ex.info@gmail.com"
                                v-model="this.cart_store_wholesale.email"
                              />
                            </div>
                            <b
                              ><vue-feather type="mail" size="15"></vue-feather>
                              {{this.currentLanguage ==='en'?"If you don't receive an SMS, Email or forget your order You can find it in the menu ":"หากไม่ได้รับ SMS Email หรือลืมคำสั่งซื้อ สามารถค้นหาได้ที่เมนู"}}
                              
                              <a href="/find-order" target="_blank"
                                ><u>{{this.currentLanguage ==='en'?"Find orders":"ค้นหาคำสั่งซื้อ"}}</u></a
                              >
                              {{this.currentLanguage ==='en'?"above":"ด้านบน"}}</b
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="media">
                        <vue-feather
                          class="m-r-30"
                          type="credit-card"
                        ></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500">
                            <h3>
                              {{this.currentLanguage ==='en'?"Payment method":"ช่องทางการชำระเงิน"}}
                              <span style="color: red">*</span>
                            </h3>
                          </h6>
                          <small class="form-text text-muted"
                            >{{this.currentLanguage ==='en'?"The system will immediately display a link to download the image. Upon successful payment, a link to download the images will be sent via SMS or email.":"ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อชำระเงินสำเร็จ, ลิงค์สำหรับดาวน์โหลดภาพจะถูกส่งให้ทาง SMS หรืออีเมลล์ด้วย"}}</small
                          >
                          <p>&nbsp;</p>
                          <div class="form-check">
                            <h6>
                              <input
                                required
                                class="form-check-input"
                                type="radio"
                                v-model="this.cart_store_wholesale.paymentMethod"
                                value="PP"
                                name="paymentMethod"
                                id="QR"
                              />
                              <label class="form-check-label" for="QR">
                                &NonBreakingSpace; <b>{{this.currentLanguage ==='en'?"PromptPay":"พร้อมเพย์ (PromptPay)"}}</b>
                                <img
                                  style="width: 100px"
                                  src="../../assets/images/running/promptpay-icon.jpg"
                                />
                              </label>
                            </h6>
                          </div>
                          <div class="form-check">
                            <input
                              disabled
                              required
                              class="form-check-input"
                              value="CC"
                              type="radio"
                              v-model="this.cart_store_wholesale.paymentMethod"
                              name="paymentMethod"
                              id="CREDIT"
                            />
                            <label class="form-check-label" for="CREDIT">
                              &NonBreakingSpace;
                              <b>{{this.currentLanguage ==='en'?"Credit Card/debit":"บัตรเครดิต/เดบิต(Credit Card/debit)"}}</b>
                              <img
                                style="width: 100px"
                                src="../../assets/images/running/credit-card-icon.png"
                              />
                              <p>
                                &nbsp;&nbsp;<small
                                  style="font-size: xx-small"
                                  class="form-text text-muted"
                                  >{{this.currentLanguage ==='en'?"3D secure credit card payments":"การชำระเงินผ่านบัตรเครดิตแบบ 3D secure"}}</small
                                >
                              </p>
                            </label>
                          </div>
                          <div class="row">&nbsp;</div>

                          <div class="row">
                            <div class="d-flex justify-content-center">
                              <button
                                type="submit"
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                                <vue-feather
                                  type="loader"
                                  size="20"
                                  animation="spin"
                                  v-if="this.is_submit_cart === 'Y'"
                                ></vue-feather>
                                {{this.currentLanguage ==='en'?"Payment":"จ่ายเงิน"}}
                              </button>
                              &nbsp;
                              <button
                                style="cursor: pointer"
                                type="reset"
                                class="btn btn-secondary btn-lg"
                              >
                                {{this.currentLanguage ==='en'?"Cancel":"ยกเลิก"}}
                              </button>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </form>
     <br>
          <form  @submit.prevent="submitCart" class="needs-validation"
            v-if="this.is_once_event_id !== 'N' && this.eventDetails.is_free_download_keep_contract === 'Y' && this.cart_store_wholesale.cal_cart.cart_final_payment === '0.00'"
          >
            <div class="col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <br>
                    <div class="card-body">
                      <div class="media">
                        <vue-feather class="m-r-30" type="info"></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500"></h6>
                          <div class="row">
                            <div class="mb-3 col-sm-6">
                              <label for="moblieNo" class="form-label"
                                ><h3>
                                  {{this.currentLanguage ==='en'?"Mobile number":"หมายเลขโทรศัพท์"}}
                                  <span style="color: red">*</span>
                                </h3>
                                
                                <small class="form-text text-muted"
                                  >{{this.currentLanguage ==='en'?"Input only number":"ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อกดปุ่มสร้างออร์เดอร์"}}
                                </small>
                              </label>
                              <input
                                required
                                maxlength="10"
                                minlength="10"
                                inputmode="numeric"
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                id="moblieNo"
                                placeholder="ex.0901234567"
                                v-model="this.cart_store_wholesale.mobileNo"
                              />
                            </div>
                            <div class="mb-3 col-sm-6">
                              <label for="email"
                                ><h3>Email <span
                                    style="
                                      font-size: small;
                                      background: inherit;
                                    "
                                    >{{this.currentLanguage ==='en'?"":"(ถ้ามี)"}}</span
                                  >
                                </h3>
                                <small class="form-text text-muted"
                                  >{{this.currentLanguage ==='en'?"Optional":"หากไม่มีให้เว้นว่าง"}}
                                </small>
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                inputmode="email"
                                placeholder="ex.abc@gmail.com"
                                v-model="this.cart_store_wholesale.email"
                              />
                            </div>
                            <b
                              ><vue-feather type="search" size="15"></vue-feather>
                              {{this.currentLanguage ==='en'?" If you forget your order You can find it in the menu ":" หากลืมคำสั่งซื้อ สามารถค้นหาได้ที่เมนู"}}
                              
                              <a href="/find-order" target="_blank"
                                ><u>{{this.currentLanguage ==='en'?"Find orders":"ค้นหาคำสั่งซื้อ"}}</u></a
                              >
                              {{this.currentLanguage ==='en'?"above":"ด้านบน"}}</b
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p>&nbsp;</p>
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <br>
                      <div class="media">
                        <vue-feather
                          class="m-r-30"
                          type="image"
                        ></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500">
                            <h3>
                              {{this.currentLanguage ==='en'?"Event free download":"กิจกรรมรับภาพฟรี"}}
                            </h3>
                          </h6>
                          <small class="form-text text-muted"
                            >{{this.currentLanguage ==='en'?"The system will immediately display a link to download the image. A link to download the image will be sent via email. (If entered email)":"ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที, ลิงค์สำหรับดาวน์โหลดภาพจะถูกส่งให้ทางอีเมลล์ (ถ้าป้อนข้อมูล)"}}</small
                          >
                          <p>&nbsp;</p>
                          
                          <div class="row">&nbsp;</div>

                          <div class="row">
                            <div class="d-flex justify-content-center">
                              <button
                                type="submit"
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                                <vue-feather
                                  type="loader"
                                  size="20"
                                  animation="spin"
                                  v-if="this.is_submit_cart === 'Y'"
                                ></vue-feather>
                                {{this.currentLanguage ==='en'?"Create order":"สร้างออร์เดอร์"}}
                              </button>
                              &nbsp;
                              <button
                                style="cursor: pointer"
                                type="reset"
                                class="btn btn-secondary btn-lg"
                              >
                                {{this.currentLanguage ==='en'?"Cancel":"ยกเลิก"}}
                              </button>
                            </div>
                          </div>

                          

                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div style="color: rgba(153, 71, 71, 0)">
            {{ this.cart_store_wholesale.total }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useCartStoreWholesale } from "../../store/modules/cart_wholesale";
import router from "../../router/index";
import axios from "axios";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: computed(
        () =>
          `รายการสั่งซื้อ และชำระเงิน ระบบจำหน่ายรูปภาพงานวิ่ง, บริการ ค้นหารอยยิ้ม | RUNNING.IN.TH`
      ),
      meta: [
        {
          name: `description`,
          content: computed(
            () =>
              `รายการสั่งซื้อ และชำระเงิน ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`
          ),
        },
        {
          name: `keywords`,
          content: computed(
            () =>
              `รายการสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,running,runninghub,facesearch`
          ),
        },
      ],
    });
    const cart_store_wholesale = useCartStoreWholesale();
    cart_store_wholesale.loadFromLocalStorage();
    return { cart_store_wholesale };
  },
  data() {
    return {
      eventDetails: {},
      cart_amount: 0,
      is_pic_me: false,
      is_mhao_flag_init: null,
      is_submit_cart: "",
      is_once_event_id: "Y",
      currentLanguage: 'th',
    };
  },
  async created() {
    if (this.cart_store_wholesale.cart.length > 0) {
      let isOnceEvent = this.checkOnceEventId();
      if (isOnceEvent === "Y") {
        this.prepareCart();
      }
    }

    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async insertLogs(image_id, event_key) {
      let data = {
        service: "cart-wholesale",
        cart_count: this.cart_store_wholesale.cart.length,
        image_id: image_id,
        event_key: event_key,
      };
      const res = await axios.post("/v1/logs/", data);
    },
    async getEventkeyById(image_id) {
      const res = await axios.get("/v1/events/event-key/" + image_id);
      return res.data.event_key;
    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      if (res.data) {
        this.eventDetails = res.data;
        this.is_mhao_flag_init = this.eventDetails.is_hybrid;
        if(this.eventDetails.is_hybrid === "Y"){
          localStorage.setItem("_event_can_mhao","YES");
          localStorage.setItem("_mhao_price",this.eventDetails.hybrid_mhao_price);
        }else{
          localStorage.setItem("_event_can_mhao","NO");
          localStorage.setItem("_mhao_price",0);
        }
        
        return res.data;
      }
    },
    prvback() {
      router.push("/");
    },
    async submitCart() {
      this.is_submit_cart = "Y";

      //load config payment gateway
      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        conf_name: "PAYMENT_GATEWAY",
      };
      const res_conf = await axios.post("v1/utils/conf", data);

      let payment_gateway = res_conf.data.body.value;
      if (payment_gateway === null || payment_gateway === "") {
        alert("พบข้อผิดพลาดกรุณาแจ้งผู้ดูแลระบบ");
      } else {
        const req = {
          mobile: this.cart_store_wholesale.mobileNo,
          email: this.cart_store_wholesale.email,
          event_key: this.eventDetails.event_key,
          amount: this.cart_store_wholesale.cal_cart.cart_amt,
          paid_amount: this.cart_store_wholesale.cal_cart.cart_final_payment,
          imageList: this.cart_store_wholesale.cart,
          image_length: this.cart_store_wholesale.cart.length,
          is_mhao: this.cart_store_wholesale.is_mhao_flag === "YES" ? "Y" : "N",
          payment_gateway: payment_gateway,
          payment_channel: this.cart_store_wholesale.paymentMethod,
        };

        //console.log("order_creation req",req)
        let order_res = await this.cart_store_wholesale.order_creation(req);
        //console.log("order_createion_r:", order_res);

        if (order_res.status === "success") {
          //console.log("success: ", order_res);

          this.is_submit_cart = "N";
          this.cart_store_wholesale.clear_all_cart_in_cart_page();
          if(this.currentLanguage === 'en'){
            await this.cart_store_wholesale.alert_create_order_en(order_res.uuid);
            this.$router.push("/download/" + order_res.uuid);
          }else{
            await this.cart_store_wholesale.alert_create_order(order_res.uuid);
            this.$router.push("/download/" + order_res.uuid);
          }

        } else {
          //console.log("order creation errorx: ", order_res);
          await this.cart_store_wholesale.alert_create_order_failed();
          this.is_submit_cart = "N";
        }
      }
    },
    async checkOnceEventId() {
      let event_id_tmp = "";
      this.is_once_event_id = "Y";

      this.cart_store_wholesale.cart.forEach((img) => {
        if (event_id_tmp === "") {
          event_id_tmp = img.event_id;
        } else {
          if (event_id_tmp !== img.event_id) {
            this.is_once_event_id = "N";
          }
        }
      });
      //console.log("checkOnceEventId:"+ this.is_once_event_id)
      this.prepareCart();
      return this.is_once_event_id;
    },
    async prepareCart() {
      if (this.cart_store_wholesale.cart.length > 0) {
        const image_id = this.cart_store_wholesale.cart[0].id;
        let _event_key = await this.getEventkeyById(image_id);
        if (_event_key !== "-") {
          let event_detail = await this.getEventDetail(_event_key);

          this.cart_store_wholesale.addPackage(event_detail.package);

          if (this.cart_store_wholesale.is_mhao_flag === "YES") {
            this.cart_store_wholesale.event_can_mhao = event_detail.is_hybrid;
            //TODO
            this.cart_store_wholesale.mhao_price = event_detail.mhao_price;
          }

          this.insertLogs(image_id, _event_key);
        }
      }
    },
  },
};
</script>

<style scoped>
.align-to-right {
  float: right;
  padding: 10px;
  background-color: hotpink;
}

input[type="checkbox"] {
  display: none;
}

div.gellerycont:hover {
  cursor: pointer;
  opacity: 0.9;
}
@media screen and (min-width: 451px) and (max-width: 9000px) {
  div.gellerycont {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
  }

  div.gellerycont img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  div.gellerycont {
    width: 49%;
    float: left;
    margin: 1px;
    padding: 2px;
    border: 1px solid #ccc;
  }

  div.gellerycont img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }
}

.gellerycont {
  position: relative;
  width: auto;
  height: auto;
}

.gellerycont img {
  height: auto;
  width: auto;
}

.gellerycont .btn {
  position: absolute;
  top: 84%;
  left: 78%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;
  color: white;
  padding: 3px 3px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
}

.gellerycont .btn:hover {
  background-color: black;
}
</style>