<template>
  <div class="col-sm-12 box-col-12">
       <div class="card">
         <div class="card-header">
           <h3>MASONRY GALLERY</h3>
         </div>


         <div class="card-body photoswipe-pb-responsive" itemprop="associatedMedia" itemscope>
           <div v-masonry transition-duration="0s" item-selector=".item" class="masonry-container row my-gallery grid gallery" >
             <div v-masonry-tile class="col-md-2 col-sm-6 isotopeSelector grid-item item" :key="index" v-for="(item, index) in imagearray" @click="() => showImg(index)" >
                <img :src="item.thumbnail_file_name" alt="Image description" class="img-thumbnail" />
             </div>
           </div>
           <vue-easy-lightbox
           :visible="visible"
           :index="index"
           :imgs="lightBoxImages" 
           @hide="handleHide"
             >
           </vue-easy-lightbox>
         </div>
       </div>
     </div>
</template>
<script>
//import {imagearray  } from "../../../data/gallery/masonryPage"
import { mapState } from "vuex";
export default {
 data() {
 return {  
   lightBoxImages:[],
         visible: false,
         index:'', 
   //imagearray: imagearray,
   imagearray: [
   {
            "original_file_name": "logo_50e0a6ac21764ea7823ae4b4c784f0a8.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/382493a59ea84cecb7e5949eaa1d9bb0.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/9859fd36de8c4beead666da1a9ce844c.jpg"
        },
        
        {
            "original_file_name": "logo_024c5fa8934c497ebe4694c19d267eb3.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/c5524e0785a54db6ae856c6025f405af.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/c243d4206050471b9e2482cf914cc103.jpg"
        },
        {
            "original_file_name": "logo_52a4f6ae039a4536bb62c47a95ad11b9.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/aac491cfc0fe45b09d72a84e2d0a86ee.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/0c4c6e7b579344a19ad78a7ed2367768.jpg"
        },
        {
            "original_file_name": "logo_18accd9529324d80882b0176e92b6125.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/4d24ff4a7eaa443b9d5a3b966c3f6dd4.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/fe58ed1fdeaf440b99d79061b09f1df8.jpg"
        },
        {
            "original_file_name": "logo_5045853b1495440e9b05fb0b5c563223.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/77a46a2b634a4eae8a1d9032668728f5.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/f33f4d45f231446d9f6bb06c72968485.jpg"
        },
        {
            "original_file_name": "logo_5dcefe15603e421586291ab2d7bd0365.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/35c8e3845d38477ca7d8e8e663229670.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/b60d3323ca3546cb8afb01d88c2e3a57.jpg"
        },
        {
            "original_file_name": "logo_023ef1786d0c40caad453ab5a56a1c91.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/8f2dd9c9cab046339ab5980a11446d84.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/a8a9ddd9c4e44bc2968a16ac047c17a5.jpg"
        },
        {
            "original_file_name": "logo_241212fe1c92427ebeb2c25bf13dbee7.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/67c36efe98644195bbcd1a98ac1867ec.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/8c1bac024fc349adae982228e7de882d.jpg"
        },
        {
            "original_file_name": "logo_46900fff11d742c6839be6883320cecb.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/f5ccca7b95e144f2949ffc4922ef88b4.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/fb01108d6f7e4d9e9a2ae662084c71b4.jpg"
        },
        {
            "original_file_name": "logo_3f5cec9d230941f4baa2e94515654a81.jpg",
            "thumbnail_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/0aac172255ee46f1b0446293f40a4d30.jpg",
            "preview_file_name": "https://rnth.s3.ap-southeast-1.amazonaws.com/bahoytrail/highlight/4a41ba6282f24fe3b9fc21aa8d6294fb.jpg"
        }
    ],
    
 };
},
methods: {
   showImg (index) {
     this.index = index
     this.visible = true
   },
   handleHide () {
     this.visible = false
   },
 },
mounted() {

   this.imagearray.forEach(item=>{
         this.lightBoxImages.push(item.preview_file_name)
     })
},
computed: {
    ...mapState({
      width: (state) => state.menu.width,
    }),
  },
watch: {
    width() {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry();
      } 
    },
  }
,
}
</script>